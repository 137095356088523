 
 

<div *ngIf="authService.userDetails?.is_superuser" style="display: flex;column-gap: 3rem; ">
    <!-- <div class="orl-area--card" style="width: 95%;"> -->
        <div style="display: flex;flex-direction: column;row-gap: .5rem;padding: .5rem;">
            <span style="font-size: 18px;font-weight:bold;padding-left:.9rem;">
                All User List
            </span>
           
    <div style="width: 100%; padding-left:1rem">
        <div class="example-container mat-elevation-z8">
            <mat-table #table [dataSource]="dataSourceUser">
          
              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
          
              <!-- Position Column -->
              <ng-container matColumnDef="User Id">
                <mat-header-cell *matHeaderCellDef> User Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="User Name">
                <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.username}} </mat-cell>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container matColumnDef="First Name">
                <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}} </mat-cell>
              </ng-container>
              
              <!-- Symbol Column -->
              <!-- <ng-container matColumnDef="Last Name">
                <mat-header-cell *matHeaderCellDef> Last Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.last_name}} </mat-cell>
              </ng-container> -->
          
                            
              <!-- Symbol Column -->
              <ng-container matColumnDef="Email">
                <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.email}} </mat-cell>
              </ng-container>
          
                            
              <!-- Symbol Column -->
              <ng-container matColumnDef="Is Staff">
                <mat-header-cell *matHeaderCellDef> Is Staff </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.is_staff}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Joined Date">
                <mat-header-cell *matHeaderCellDef> Joined Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.date_joined | date: 'dd/MM/yyyy hh:mm a'}} </mat-cell>
              </ng-container>
     
              <!-- <ng-container matColumnDef="Last Active">
                <mat-header-cell *matHeaderCellDef> Last Active </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.last_login | date: 'dd/MM/yyyy hh:mm a'}} </mat-cell>
              </ng-container> -->

              <mat-header-row *matHeaderRowDef="displayedUserColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedUserColumns;"></mat-row>
            </mat-table>
          </div>

          
        
    </div>

    <div style="display: flex;column-gap: 1rem;align-items: center;font-weight: bold;">
      <div style="font-size: 19px;width: 12rem;color: gray;">Export User Data</div>
      <button mat-icon-button mat-button (click)="downloadUserData()"
          style="cursor: pointer;height: 3.5rem;width: 3.5rem;">
          <mat-icon style="font-size: 25px;color: #26a0da;">get_app</mat-icon>
      </button>
  </div>
        </div>
    <!-- </div> -->
</div>
