import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeedbackRoutingModule } from './feedback-routing.module';
import { FeedbackPageComponent } from './components/feedback-page/feedback-page.component';
import { FeedbackListComponent } from './components/feedback-list/feedback-list.component';
import { FeedbackDetailsComponent } from './components/feedback-details/feedback-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [FeedbackPageComponent, FeedbackListComponent, FeedbackDetailsComponent],
  imports: [
    CommonModule,
    FeedbackRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class FeedbackModule { }
