<div >
    <div *ngIf="orlHomepageLoader"
        style="display: flex;justify-content: center;align-items: center;width: 90%;height: calc(100vh - 70px);">
        <div class="loader"></div>
    </div>   
    
    <div *ngIf="!orlHomepageLoader">
        <div style="display: flex;justify-content: center;flex-direction: column;padding: 2rem 0 0 1rem; row-gap:1.5rem;">
            <span style="display: flex; font-size: 50px; font-weight: bold;">
                Welcome, {{authService.userDetails?.username}}
            </span>
            <span style="display: flex; font-size: 20px;">
                Last Login {{authService.userDetails?.last_login}}
            </span>
        </div>
    
        <div style="display: flex;column-gap: 1rem;padding:1rem 0px 0rem 1rem;">
            <div class="orl-area--card" style="width: 45%;">
                <div style="display: flex;flex-direction: column;row-gap: .5rem;padding: .5rem;">
                    <span style="font-size: 18px;font-weight:bold;padding-left:.5rem;">
                        {{ authService.userDetails?.is_superuser ? 'Feedback' : 'Available Courses' }}
                    </span>
                    <span>
                        <ol style="
                        display: flex;
                        flex-direction: column;
                        row-gap: .5rem;
                    ">
                            <li *ngFor="let card of orlFirstCard">{{card.value}}</li>
                        </ol>
                    </span>
                </div>
            </div>
    
            <div *ngIf="authService.userDetails?.is_superuser; else secCardBlock" class="orl-area--card" style="width: 45%;">
                <div style="display: flex;flex-direction: column;row-gap: 1rem;padding: .5rem;">
                    <span style="font-size: 18px;font-weight:bold;padding-left:.5rem;">
                        Resource in use
                    </span>
    
                    <span *ngIf="orlResourceIpList?.length > 0; else labBlock" 
                        style="padding-left: .5rem;display: flex;row-gap: .5rem;flex-direction: column;">
    
                        <span *ngFor="let res of orlResourceIpList" style="display: flex;column-gap: .5rem;">
                            <span style="color: gray;">{{res?.username}}</span>
                             --
                            <span>{{res?.ip}}</span>
                        </span>
                    </span>
                    <ng-template #labBlock><span style="padding-left: .5rem;">---</span></ng-template>
                </div>
            </div>
            <ng-template #secCardBlock>
                <div class="orl-area--card" style="width: 45%;">
                    <div style="display: flex;flex-direction: column;row-gap: .5rem;padding: .5rem;">
                        <span style="font-size: 18px;font-weight:bold;padding-left:.5rem;">
                            Billing Info
                        </span>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

</div>
 