import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import {
  MatSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.scss']
})
export class FeedbackPageComponent implements OnInit {
  orlFeedbackForm: any;
  /** DOCS Vars */
  public orlDocs: any;
  public orlDocName: any;
  public orlDocType: any;
  public orlDocValueName: any;

  public orlFeedbackEndPointUrl: string = '';
  constructor(public fb: FormBuilder, public sharedService: SharedService,
    public snackBar: MatSnackBar, public authService: AuthService, public route: Router) {
    this.orlFeedbackEndPointUrl = this.sharedService.onRequestLabService?.feedback;
  }

  ngOnInit(): void {
    this.sharedService.setHeader('FeedBack')
    this.orlFeedbackForm = this.fb.group({
      subject: [null, Validators.required],
      description: [null, Validators.required],
      attachments: [null]
    })
  }

  submitRequestForm() {
    let url = 'feedback_vc/';
    let formDataVal = new FormData();
    formDataVal.append('user', this.authService.userDetails?.id),
    formDataVal.append('subject', this.orlFeedbackForm.controls.subject.value),
    formDataVal.append('description', this.orlFeedbackForm.controls.description.value),
    this.orlFeedbackForm.controls.attachments.value !== null ? 
    formDataVal.append('upload', this.orlFeedbackForm.controls.attachments.value) : null;

    // let payload = {
    //   username: this.orlFeedbackForm.controls.username.value,
    //   subject: this.orlFeedbackForm.controls.subject.value,
    //   description: this.orlFeedbackForm.controls.description.value
    // }
    this.authService.post(this.orlFeedbackEndPointUrl + url, formDataVal).subscribe((response: any) => {
      this.sharedService.showmsg("Feedback saved successfully.");
      this.route.navigate(['/landing-page']);
    }, error => {this.sharedService.showmsg(error.message);})
  }
  //Convert local file to base64
  convertBase64(url: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = (u: any) => {
      var reader = new FileReader();
      reader.onloadend = (e: any) => {
        this.orlDocs = e.target.result;
        let p40sImageFileName = this.orlDocName;
        const imageBlob = this.convertImageFile(this.orlDocs);
        const imageFile = new File([imageBlob], p40sImageFileName, { type: "image/jpeg" });
        this.orlFeedbackForm.get('attachments').setValue(imageFile)
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  //Convert base64 to imagefile
  convertImageFile(base64: any) {
    const byteString = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg' });
    return blob;
  }

  //Change tool photo
  changeDoc(toolImageInput: any) {
    this.orlFeedbackForm.get('attachments').setValue(toolImageInput.target.files[0])
    // this.p40sToolImageError = '';
    // this.p40bToolImageValueReject = false;
    if (toolImageInput.target.files && toolImageInput.target.files[0]) {
      const max_size = 5242880;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];

      if (toolImageInput.target.files[0].size > max_size || !allowed_types.includes(toolImageInput.target.files[0].type)) {
        if (toolImageInput.target.files[0].size > max_size) {
          // this.p40sToolImageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
          // this.p40bToolImageValueReject = true;
          this.orlDocs = "";
        }

        if (!allowed_types.includes(toolImageInput.target.files[0].type)) {
          // this.p40sToolImageError = 'Only jpg, jpeg and png files are allowed';
          // this.p40bToolImageValueReject = true;
          this.orlDocs = "";
        }
      }
      else {
        // this.orlDocValueName =  toolImageInput.target.files[0].name; 
        // this.orlDocType = toolImageInput.target.files[0].type;
        // const reader = new FileReader();
        // reader.onload = (e: any) => {
        //   // this.p40bIsImageFile = true;
        //   this.orlDocs = e.target.result;    
        //   let p40sImageFileName = toolImageInput.target.files[0].name;
        //   let p40sImageFileType = toolImageInput.target.files[0].type;
        //   const imageBlob = this.convertImageFile(this.orlDocs);
        //   const imageFile = new File([imageBlob], p40sImageFileName, { type: p40sImageFileType });
        // };
        // reader.readAsDataURL(toolImageInput.target.files[0]);
      }
    }
  }
}
