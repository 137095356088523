<div class="orl-area--card" 
    style="height: calc(100vh - 1.5rem);margin-top: -7px;display: flex;flex-direction: column;row-gap: .5rem;">
    <div style="height: 5rem;"></div>
    <div>
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/homepage'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">home</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Home</span>
            </div>
        </button>
    </div>
    <div *ngIf="!authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/request-lab'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">science</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Lab</span>
            </div>
        </button>
    </div>
    <div *ngIf="!authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/feedback'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">forum</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Feedback</span>
            </div>
        </button>
    </div>

    <div *ngIf="authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/instance-list'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">forum</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Instance-list</span>
            </div>
        </button>
    </div>


    <div *ngIf="authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/user-list'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">forum</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">User-list</span>
            </div>
        </button>
    </div>


    <div *ngIf="authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/feedback-list'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">forum</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Feedback-list</span>
            </div>
        </button>
    </div>

    <div *ngIf="!authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/support'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">contact_support</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Support</span>
            </div>
        </button>
    </div>
    <div *ngIf="authService.userDetails?.is_superuser">
        <button style="height: 5rem;width: 5rem;" mat-button [routerLink]="'/support-list'">
            <div style="display: flex;align-items: center;flex-direction: column;row-gap: .5rem;">
                <mat-icon style="font-size: 30px;color: #26a0da;display: flex;justify-content: center;">contact_support</mat-icon>
                <span style="font-weight: bold;font-size: 12px;">Support-list</span>
            </div>
        </button>
    </div>

</div>
