
<div *ngIf="authService.userDetails?.is_superuser" style="display: flex;column-gap: 1rem; padding: 1rem 0 0 0">
  <!-- <div class="orl-area--card" style="width: 100%;" > -->
      <div style="display: flex;flex-direction: column;row-gap: .5rem;padding: .5rem;">
          <span style="font-size: 18px;font-weight:bold;padding-left:.9rem;">
              {{ authService.userDetails?.is_superuser ? 'Instance List' : 'Available Courses' }}
          </span>
         
    <div style="display: flex;flex-direction: rows;column-gap: 2rem; ">
      <select style="
      height: 2.6rem;
      width: 13rem; margin-left: 1rem; margin-right: -0.7rem; font-weight: bold;
  " id="selectInput" (change)="onChange($event)">
          <option *ngFor="let option of options" [value]="option.value">
          {{ option.viewValue }}
          </option>
      </select> 
 
      <input style="
      height: 2.4rem;
      width: 13rem; margin-right: -0.7rem; font-weight: bold;
  " id="selectInputUserName" placeholder=" Enter User Name" type="text" #selectInputUserName />

       <button style="
       height: 2.6rem;
       width: 8rem; margin-right: -0.7rem; font-weight: bold;
   "
    (click)="getFliterInstane()">Filter</button>
        </div>
  <div style="width: 100%; padding-left:1rem">

      <div class="example-container mat-elevation-z8">


          <mat-table #table [dataSource]="dataSource">
        
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
        
            <!-- Position Column -->
            <ng-container matColumnDef="Instance Id">
              <mat-header-cell *matHeaderCellDef> Instance Id </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.user_instance_id}} </mat-cell>
            </ng-container>
        
            <!-- Name Column -->
            <ng-container matColumnDef="User Name">
              <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.userName}} </mat-cell>
            </ng-container>
        
            <!-- Weight Column -->
            <ng-container matColumnDef="IP Address">
              <mat-header-cell *matHeaderCellDef> IP Address </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.instance_ip}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Instance Type">
              <mat-header-cell *matHeaderCellDef> Instance Type </mat-header-cell>
              <mat-cell *matCellDef="let element"> Terra Form </mat-cell>
          </ng-container>
            
            <!-- Symbol Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="Timestamp">
              <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.timestamp | date: 'dd/MM/yyyy hh:mm a'}} </mat-cell>
            </ng-container>
        
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>

        
      
  </div>

  <div style="display: flex;column-gap: 1rem;align-items: center;font-weight: bold;">
    <div style="font-size: 19px;width: 12rem;color: gray;">Export Instance Data</div>
    <button mat-icon-button mat-button (click)="downloadData()"
        style="cursor: pointer;height: 2.5rem;width: 2.5rem;">
        <mat-icon style="font-size: 25px; background-color: #26a0da; color:white">get_app</mat-icon>
    </button>
</div>

      </div>
  <!-- </div> -->
</div>