import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import { LabComponent } from './components/lab/lab.component';
import { MatIconModule } from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from './services/auth.service';  
import { RegisterComponent } from './components/register/register.component';
import {MatSelectModule} from '@angular/material/select';  
import { UserlistPageComponent } from './components/userlist-page/userlist-page.component';
import { InstancelistPageComponent } from './components/instancelist-page/instancelist-page.component';
@NgModule({
  declarations: [LoginComponent, RegisterComponent, LandingPageComponent, UserDetailsComponent, 
     LabComponent, UserlistPageComponent, InstancelistPageComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDialogModule
  ],
  providers:[AuthService]
})
export class AuthModule { }
