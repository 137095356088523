import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { MatDialog } from '@angular/material/dialog'; 
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styleUrls: ['./support-list.component.scss']
})
export class SupportListComponent implements OnInit {
  orlTableHeader: string[] = ['support_id','subject', 'ipAddress', 'issues', 'instanceId', 'description', 'timestamp', 'upload'];
  public p40TableLoader: boolean = false;

  public orlSupportList: any[] = [
    // {username: 'test', email: 'test@gmail.com', subject: 'test1', description: 'test1test1'},
    // {username: 'test1', email: 'test1@gmail.com', subject: 'test11', description: 'test11test11'}
  ];
  public orlSupportEndPointUrl: string = '';
  constructor(public sharedService: SharedService, public authService: AuthService,
    private matdialog : MatDialog) { 
    this.orlSupportEndPointUrl = this.sharedService.onRequestLabService?.support;
  }

  ngOnInit(): void {
    this.p40TableLoader = true;
    this.sharedService.setHeader('Support List')
    this.authService.get(this.orlSupportEndPointUrl + 'support_vc/').subscribe((response:any)=>{
      this.orlSupportList = response;
      this.p40TableLoader = false;
    })
  }

  openSupportDetail(data: any){
    const dialogueRef = this.matdialog.open(SupportListComponent, {
      width: '95rem',
      data: data
    });
    dialogueRef.afterClosed().subscribe((result) => {
      if(result?.success == true){}
    });
  }

}
