<div class="orl-area--card orl-gradient-color" style="height: 3rem;border-radius: 0 0 6px 6px;
margin:-7px 0 0 0;">
    <div style="display: flex; justify-content: space-between;height:100%;align-items:center;">
        <div style="display: flex;column-gap: .5rem;">
            <button mat-icon-button (click)="sharedService.orlIsSideNav = !sharedService.orlIsSideNav">
                <mat-icon>menu</mat-icon>
            </button>

            <span style="align-self: center;font-size: 18px;font-weight: bold;">
                {{p40sHeader}}
            </span>
        </div>
        <button [matMenuTriggerFor]="userMenu" style="display: flex;column-gap: .5rem;background: none;color: white;border: none;"
        #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()">
            <span class="orl-vsm-crcle-prof" style="align-self: center;">
                <div class="orl-vsm-crcle-prof-preview">
                    <div [style.backgroundImage]="'url('+ './assets/images/userprofile.png' +')'"></div>
                </div>
            </span>   
            <span style="align-self: center;cursor: pointer;" (click)="route.navigate(['/user-details'])">
                {{authService.userDetails?.username}}
            </span>
        </button>
        <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (mouseleave)="menuTrigger.closeMenu()" (click)="logout()">Logout</button>
        </mat-menu>
    </div>
</div>
