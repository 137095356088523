import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: any;
  public orlUsersEndPointUrl: string = '';
  constructor(public sharedService: SharedService, public route: Router,
  public formBuilder: FormBuilder, public authService: AuthService) { 
    this.orlUsersEndPointUrl = this.sharedService.onRequestLabService?.users;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: ['123']
    })
  }

  submitLogin(){
    let url = 'login/';
    let payload = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value 
    }
    this.authService.post(this.orlUsersEndPointUrl + url, payload).subscribe((response:any)=>{
      this.sharedService.orlIsLoggedIn = true;
      this.authService.userDetails = response?.user;
      this.sharedService.showmsg(response.message);
      this.viewInstance();
      this.invokeUserInstance();
      this.route.navigate(['/homepage']);
    }, error => this.sharedService.showmsg(error?.error?.message))
  }
  registerPath(){
    this.route.navigate(['/register']);  
  }
  invokeUserInstance(){
    setTimeout(() => {
      this.viewInstance(); // Execute immediately
      this.sharedService.orlInstanceInterval = setInterval(() => {
        this.viewInstance(); // Execute every second
      }, 5000);
    }, 0); // Zero delay for immediate execution
  }

  viewInstance(){
    let url2 = 'update_active/'
    this.authService.get
    (this.orlUsersEndPointUrl + url2 + this.authService?.userDetails?.id + '/').subscribe((response:any)=>{
    }, error => clearInterval(this.sharedService.orlInstanceInterval))
  }
}
