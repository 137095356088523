<div style="display: flex;justify-content: center;align-items: center;height: calc(100vh - 6rem);width: 100%;">
    <button class="orl-secondary-button" style="display: flex;align-items: center;column-gap: .3rem;">

        <span>
            Generate Instance
        </span>

        <span>
            <img src="./assets/images/generate instance.PNG" height="25" width="25" style="
            vertical-align: baseline;">
        </span>
    </button>
</div>
