import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LabComponent } from './components/lab/lab.component';
import { AuthServiceGuard } from './services/auth-service.guard';
import { RegisterComponent } from './components/register/register.component';
import { UserlistPageComponent } from './components/userlist-page/userlist-page.component';
import { InstancelistPageComponent } from './components/instancelist-page/instancelist-page.component';

const routes: Routes = [
  { path: 'request-lab', component: LabComponent, canActivate:[AuthServiceGuard] },
  { path: 'user-list', component: UserlistPageComponent, canActivate:[AuthServiceGuard] },
  { path: 'instance-list', component: InstancelistPageComponent, canActivate:[AuthServiceGuard] },
  { path:'register', component: RegisterComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
