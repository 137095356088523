import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDetailsComponent } from '../feedback-details/feedback-details.component';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {
  orlTableHeader: string[] = ['userId', 'subject', 'description', 'timestamp', 'upload'];
  public p40TableLoader: boolean = false;

  public orlFeedbackList: any[] = [
    // {username: 'test', email: 'test@gmail.com', subject: 'test1', description: 'test1test1'},
    // {username: 'test1', email: 'test1@gmail.com', subject: 'test11', description: 'test11test11'}
  ];
  public orlFeedbackEndPointUrl: string = '';
  constructor(public sharedService: SharedService, public authService: AuthService,
    private matdialog : MatDialog) { 
    this.orlFeedbackEndPointUrl = this.sharedService.onRequestLabService?.feedback;
  }

  ngOnInit(): void {
    this.p40TableLoader = true;
    this.sharedService.setHeader('FeedBack List')
    this.authService.get(this.orlFeedbackEndPointUrl + 'feedback_vc/').subscribe((response:any)=>{
      this.orlFeedbackList = response;
      this.p40TableLoader = false;
    })
  }

  openFeedbackDetail(data: any){
    const dialogueRef = this.matdialog.open(FeedbackDetailsComponent, {
      width: '95rem',
      data: data
    });
    dialogueRef.afterClosed().subscribe((result) => {
      if(result?.success == true){}
    });
  }

}
