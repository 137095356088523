
<div style="display: flex;flex-direction: column;row-gap: 1rem;">
    <div style="font-size: 30px;font-weight: bold;text-align: center;height:8vh;">
        Support Details
    </div>
    <div style="display: flex;flex-direction: column; row-gap: .5rem;">
        <div style="display: flex;">
            <div style="width: 15%;font-weight: bold;color: gray;">Description: </div>
            <div style="width: 85%;height: calc(100vh - 26rem);overflow:auto;">
                {{data.description}}
            </div>
        </div>
        <div *ngIf="data?.upload != null" style="display: flex;">
            <div style="width: 15%;font-weight: bold;color: gray;align-self: center;">View Doc: </div>
            <div style="width: 85%;cursor: pointer;"  (click)="viewDoc()">
                <!-- <button mat-icon-button mat-button 
                    style="cursor: pointer;height: 3.5rem;width: 3.5rem;">
                    <mat-icon style="font-size: 25px;color: #26a0da;">visibility</mat-icon>
                </button> -->
                {{data.upload.split('/')[3]}}
            </div>
        </div>
    </div>
    <div style="text-align: center;">
        <button class="orl-secondary-button" mat-dialog-close>
            Cancel
        </button>
    </div>
</div>
