import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import {
  MatSnackBar,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss']
})
export class SupportPageComponent implements OnInit {
  orlSupportForm: any;
  /** DOCS Vars */
  public orlDocs: any;
  public orlDocName: any;
  public orlDocType: any;
  public orlDocValueName: any;

  public orlSupportEndPointUrl: string = '';
  constructor(public fb: FormBuilder, public sharedService: SharedService,
    public snackBar: MatSnackBar, public authService: AuthService, public route: Router) {
    this.orlSupportEndPointUrl = this.sharedService.onRequestLabService?.support;
  }

  ngOnInit(): void {
    this.sharedService.setHeader('Support')
    this.orlSupportForm = this.fb.group({
      subject: [null],
      description: [null],
      ipAddress: [null],
      issues: [null],
      instanceId: [null],
      attachments: [null]
    })
  }

  submitRequestForm() {
    let url = 'support_vc/';
    let formDataVal = new FormData();
    formDataVal.append('user', this.authService.userDetails?.id),
    formDataVal.append('subject', this.orlSupportForm.controls.subject.value),
    formDataVal.append('ipAddress', this.orlSupportForm.controls.ipAddress.value),
    formDataVal.append('issues', this.orlSupportForm.controls.issues.value), 
    formDataVal.append('instanceId', this.orlSupportForm.controls.instanceId.value),
    formDataVal.append('description', this.orlSupportForm.controls.description.value),
    this.orlSupportForm.controls.attachments.value !== null ? 
    formDataVal.append('upload', this.orlSupportForm.controls.attachments.value) : null;

    // let payload = {
    //   username: this.orlSupportForm.controls.username.value,
    //   subject: this.orlSupportForm.controls.subject.value,
    //   description: this.orlSupportForm.controls.description.value
    // }
    this.authService.post(this.orlSupportEndPointUrl + url, formDataVal).subscribe((response: any) => {
      this.sharedService.showmsg("Support saved successfully.");
      this.route.navigate(['/landing-page']);
    }, error => {this.sharedService.showmsg(error.message);})
  }
  //Convert local file to base64
  convertBase64(url: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = (u: any) => {
      var reader = new FileReader();
      reader.onloadend = (e: any) => {
        this.orlDocs = e.target.result;
        let p40sImageFileName = this.orlDocName;
        const imageBlob = this.convertImageFile(this.orlDocs);
        const imageFile = new File([imageBlob], p40sImageFileName, { type: "image/jpeg" });
        this.orlSupportForm.get('attachments').setValue(imageFile)
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  //Convert base64 to imagefile
  convertImageFile(base64: any) {
    const byteString = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpg' });
    return blob;
  }

  //Change tool photo
  changeDoc(toolImageInput: any) {
    this.orlSupportForm.get('attachments').setValue(toolImageInput.target.files[0])
    // this.p40sToolImageError = '';
    // this.p40bToolImageValueReject = false;
    if (toolImageInput.target.files && toolImageInput.target.files[0]) {
      const max_size = 5242880;
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];

      if (toolImageInput.target.files[0].size > max_size || !allowed_types.includes(toolImageInput.target.files[0].type)) {
        if (toolImageInput.target.files[0].size > max_size) {
          // this.p40sToolImageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
          // this.p40bToolImageValueReject = true;
          this.orlDocs = "";
        }

        if (!allowed_types.includes(toolImageInput.target.files[0].type)) {
          // this.p40sToolImageError = 'Only jpg, jpeg and png files are allowed';
          // this.p40bToolImageValueReject = true;
          this.orlDocs = "";
        }
      }
      else {
        // this.orlDocValueName =  toolImageInput.target.files[0].name; 
        // this.orlDocType = toolImageInput.target.files[0].type;
        // const reader = new FileReader();
        // reader.onload = (e: any) => {
        //   // this.p40bIsImageFile = true;
        //   this.orlDocs = e.target.result;    
        //   let p40sImageFileName = toolImageInput.target.files[0].name;
        //   let p40sImageFileType = toolImageInput.target.files[0].type;
        //   const imageBlob = this.convertImageFile(this.orlDocs);
        //   const imageFile = new File([imageBlob], p40sImageFileName, { type: p40sImageFileType });
        // };
        // reader.readAsDataURL(toolImageInput.target.files[0]);
      }
    }
  }
}
