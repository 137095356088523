import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table/table-data-source';
import * as XLSX from 'xlsx'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
 

export interface DialogData {
  status: boolean;
  rentTime: string;
}
@Component({
  selector: 'app-lab',
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss']
})



export class LabComponent implements OnInit, OnDestroy {
  [x: string]: any;
  

  runningINSId : number | undefined
  displayedColumns = ['Instance Id', 'User Name', 'IP Address', 'Instance Type', 'status', 'Timestamp'];

  public dataSource: Element[] = [];
  public orlLabsEndPointUrl: string = '';
  public orlUserEndPointUrl: string = '';
  public orlLabDetails: any;
  public orlLabDetails1: string = '';
  public orlHasInstance: boolean = false;
  public orlInstanceInterval: any;
  public orlButtonStatus: string = '';
  public orlLabLoader: boolean = false;
  public orlIsInstanceError:boolean = false;
  constructor(public sharedService: SharedService, public authService: AuthService, public dialog: MatDialog) { 
    this.orlLabsEndPointUrl = this.sharedService.onRequestLabService?.lab;
    this.orlUserEndPointUrl = this.sharedService.onRequestLabService?.users;
  }


  status: string | undefined;
  rentTime: string | undefined;
  // otTime: string | undefined
  // @ViewChild('timeot') otTimes!: ElementRef;

  openOverTime(): void {
    const dialogRef = this.dialog.open(DialogOverTime, {
      width: '450px',
      data: {status: this.status, rentTime: this.rentTime}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      let url = this.orlUserEndPointUrl + 'orl/cldinst/';
    let payload = {  
      "status": "yes",
      "dt":  new Date(),
      "user_id": this.authService.userDetails?.id,
      "instance_id":  this.runningINSId,
      "rentHours":  localStorage.getItem("upcomeRTime"),
    }

    this.authService.put(url, payload)
    .subscribe((response:any)=>{
      let data = response
    console.log(data,"result");
    });
    this.viewInstance();
    this.invokeViewInstance();
 
      // this.rentTime = result;
      // console.log(result,".....");/

      // this.otTime = this.otTimes.nativeElement.value;
     
    });
  }

  ngOnInit(): void {
    // this.openOverTime()
    this.sharedService.setHeader('Lab');
    this.orlLabLoader = true;
    this.viewInstance();
    this.getInstaneList();
  }

  ngOnDestroy(): void {

    try{this.clrInstInterval()}catch{}
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event:any) {
    if(this.orlButtonStatus == 'Launching'){
      event.preventDefault();
      event.returnValue = 'Server is running it will destroy automatically after 30 mins.';
    }
    return false;

    //I have used return false but you can your other functions or any query or condition
  }

  form = new FormGroup({
    scriptType: new FormControl(),
    instance_size: new FormControl("T3 Micro"),
    hours: new FormControl("1"),
    rentHours: new FormControl(),
  });

  getInstaneList(){
    let url = 'userinst/' + this.authService.userDetails?.id;
    // alert(this.authService.userDetails?.id)
    this.authService.post(this.orlLabsEndPointUrl + url, {})
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.dataSource = this.orlLabDetails;
      console.log(response, "lists")
    })
  }
  
  launchInstance(){
    let url = this.orlUserEndPointUrl + 'orl/cldinst/';
    let payload = {
      "action": this.form.value.scriptType,
      "user_id": this.authService.userDetails?.id,
      "hours":  this.form.value.hours ,
      "instance_size":  this.form.value.instance_size,
      "rentHours":  this.form.value.rentHours||"00:00",
    }
    // if(!payload.action) {this.sharedService.showmsg("Please Select The Instance Script");  this.orlButtonStatus = ''; this.clrInstInterval();this.invokeViewInstance();}
    // else if(!payload.rentHours) {this.sharedService.showmsg("Please Set Rental Time");  this.orlButtonStatus = ''; this.clrInstInterval();this.invokeViewInstance();}
 
      this.authService.post(url, payload)
      .subscribe((response:any)=>{
        let data = response
      console.log(data,"result");
      });
      this.viewInstance();
      this.invokeViewInstanceFast();
    
  }

  viewInstance(){
    // this.orlLabLoader = true;
    let url = 'userinst/' + this.authService.userDetails?.id;
    // alert(this.authService.userDetails?.id)
    this.authService.get(this.orlLabsEndPointUrl + url)
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.orlLabDetails1 = response[0]?.instance_ip
      let retime =  response[0]?.rentHours
      this.runningINSId = response[0]?.user_instance_id
      let redate = new Date(response[0]?.rentDate) 
      let currentDate = new Date() 
      let cd = currentDate.getDay()
      let cm = currentDate.getMonth()
      let cy = currentDate.getFullYear()

      let red = redate.getDay()
      let rem = redate.getMonth()
      let rey = redate.getFullYear()  
      
      if(cd==red && cm==rem&& cy==rey){
        let chh = currentDate.getHours()
        let cmm = currentDate.getMinutes()

        if(retime.split(":")[0]==chh && parseInt(retime.split(":")[1])-10==cmm && !localStorage.getItem("upcomeR") ){
           
           this.openOverTime()
           localStorage.setItem("upcomeR","opened")
        }
        if(retime.split(":")[0]==chh && parseInt(retime.split(":")[1])==cmm){
          if (localStorage.getItem("upcomeR")=="false") {
            this.destroyInstance(response[0]?.user_instance_id) 
          }else{
            console.log("extends");
            
          }
       }
      }


      console.log(currentDate.getTime(), currentDate.getDate(),"======");
      
      // if(this.orlLabDetails>0){
      //   this.orlButtonStatus = '';
      //   this.clrInstInterval();
      // }
      if(this.orlLabDetails.length > 0){
        this.orlHasInstance = true;
        if(this.orlLabDetails[0].status === "Launching"){
          this.orlButtonStatus = 'Launching';
          if(this.orlInstanceInterval == undefined){
            this.invokeViewInstance();
          }
        }
        else if(this.orlLabDetails[0].status === "Destroying"){
          this.orlButtonStatus = 'Destroying';
          if(this.orlInstanceInterval == undefined){
            this.invokeViewInstance();
          }
        }

        else if(this.orlLabDetails[0].status === "Launched"){
          this.orlButtonStatus = '';
          this.clrInstInterval();
        }
        this.orlLabLoader = false;
      }
      else{
        this.orlHasInstance = false;
        // this.orlLabLoader = true;
        // this.clrInstInterval();
        this.orlLabLoader = false;
        this.orlButtonStatus = 'Destroying';
        if(this.orlInstanceInterval == undefined){
          this.invokeViewInstance();
        }

      }
      this.getInstaneList()
    }, error => {
      this.sharedService.showmsg(error.message);
      this.clrInstInterval();
    })
  }


  //     if(this.orlLabDetails.length > 0){
  //       this.orlHasInstance = true;
  //       console.log("gjhgfjj",this.orlLabDetails[0].status)
  //       if(this.orlLabDetails[0].status==="start"){ 
  //         console.log(this.orlLabDetails[0].status, "daaaaa")
  //         // alert("wait")
  //         this.orlButtonStatus = 'Launching';
  //         if(this.orlInstanceInterval == undefined){
  //           this.invokeViewInstance();
  //         }
  //       }
  //       // else if(this.orlLabDetails[0].status === "empty" ){ 
 
  //       //   this.orlButtonStatus = '';
  //       //   this.clrInstInterval();
  //       //   // this.orlHasInstance = false;
  //       //   // this.orlLabLoader = true;
  //       //   // this.clrInstInterval();
  //       //   // this.orlLabLoader = false;
  //       // }
  //       else if(this.orlLabDetails[0].status === 'Launched' ){ 
  //         this.orlButtonStatus = '';
  //         this.clrInstInterval();
  //       }
  //       else if(this.orlLabDetails[0].status === 'Destroyed'){
  //         this.orlButtonStatus = 'Destroying';
  //         if(this.orlInstanceInterval == undefined){
  //           this.invokeViewInstance();
  //         }
  //       }
  //       else if(this.orlLabDetails[0].status === 'empty'){
  //         alert("hi")
  //         this.orlButtonStatus = '';
  //         this.clrInstInterval();
  //       }
  //       this.orlLabLoader = false;
  //     }
  //     else{
  //       this.orlHasInstance = false;
  //       // this.orlLabLoader = true;
  //       // this.clrInstInterval();
  //       this.orlLabLoader = false;
  //     }

  //   }, error => {
  //     this.sharedService.showmsg(error.message);
  //     this.clrInstInterval();
  //   })
  // }


  destroyInstance(instance_id: number){
    
    const al = this.orlLabDetails.map((e:any)=>{
      if(e.status==="Launched"){
        this.dataInstId = e.user_instance_id
      }
    })
    this.orlButtonStatus = 'Destroying';
    if(this.orlInstanceInterval == undefined){
      this.invokeViewInstance();
    }
    console.log(al,"jkjkjkjkjkj", this.dataInstId)
  
    let url = this.orlUserEndPointUrl + 'orl/cldinst/';
    let payload = {
      "action": "1",
      "user_id": this.authService.userDetails?.id,
      "user_instance_id": this.dataInstId
    } 
  
    this.authService.post(url, payload)
    .subscribe((response:any)=> {
      this.orlButtonStatus = 'Destroying';
      if(this.orlInstanceInterval == undefined){
        this.invokeViewInstance();
      }
      this.sharedService.showmsg(response.message)
      localStorage.removeItem("upcomeR")
      this.invokeViewInstanceFast();
    })
    // this.viewInstance();
    this.invokeViewInstance();
  }

  invokeViewInstance(){
    this.orlInstanceInterval = setInterval(() => {
      this.viewInstance();
    }, 30000)
  }
  invokeViewInstanceFast(){
    this.orlInstanceInterval = setInterval(() => {
      this.viewInstance();
    }, 2000)
  }
  clrInstInterval(){
    clearInterval(this.orlInstanceInterval)
    this.orlInstanceInterval = undefined;
  }

  downloadPem(){
    window.open(environment.apiURL + 'media/onreqlab.ppk')
  }

  downloadPutty(){
    const filePath = '/assets/PuTTY_Tutorial.pdf';
    const link = document.createElement('a');
    link.href = filePath;
    link.download = 'PuTTY_Tutorial.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  


  downloadData():void{
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    XLSX.writeFile(wb, 'DataExport.xlsx');
  }
}


export interface Element {
  user_instance_id: number,
  userName: string,
  instance_ip: number;
  status: string;
  // processing_status: boolean;
  timestamp: string;
  instance_type: string;
  // userName: string,
  // Logs: string;
  // comments: number;
}

// const ELEMENT_DATA: Element[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
//   {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
//   {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
//   {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
//   {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
//   {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
//   {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
//   {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
//   {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
//   {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
//   {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
// ];


@Component({
  selector: 'dialog-overTime',
  templateUrl: 'dialog-overTime.html',
})
export class DialogOverTime implements OnInit{
 

  @ViewChild('selectInputUserName') userNameInput!: ElementRef;

  userName: string = '';
 
  constructor(
    public dialogRef: MatDialogRef<DialogOverTime>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  ngOnInit(): void {
     
  }

  onNoClick(): void {
    this.dialogRef.close();
    // alert("destory");
    localStorage.setItem("upcomeR", "false")
    console.log("destory");
  }


  okClick(): void {
    this.userName = this.userNameInput.nativeElement.value;
    // alert(this.userName);
    console.log(this.userName);
    localStorage.setItem("upcomeR", "true")
    localStorage.setItem("upcomeRTime", this.userName)
    
    this.dialogRef.close();
  }
  
}