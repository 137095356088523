<!--Log In-->
<div *ngIf="!sharedService.orlIsLoggedIn">
  <router-outlet></router-outlet>
</div>

<!--Logged In-->
<div *ngIf="sharedService.orlIsLoggedIn">
  <div style="display: flex;height: 100%;">
    <div *ngIf="sharedService.orlIsSideNav" style="width: min-content;height: 100%;">
      <app-side-menu></app-side-menu>
    </div>
    <div style="width: 100%;height:calc(100vh - 99px);">
      <app-header></app-header>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
