import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { AuthService } from '../../services/auth.service';

interface Option {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-registers',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public RegisterForm: any;
  public orlUsersEndPointUrl: string = '';

  options = [
    { value: '1', viewValue: 'User' },
    { value: '0', viewValue: 'Admin' }, 
  ];
  selectedOption: string | undefined  

  constructor(public sharedService: SharedService, public route: Router,
  public formBuilder: FormBuilder, public authService: AuthService) {  
 
    this.orlUsersEndPointUrl = this.sharedService.onRequestLabService?.users;
  }


 

  ngOnInit(): void {
    this.selectedOption = "1"
    this.RegisterForm = this.formBuilder.group({
      username: [null, Validators.required],
      first_name: [null, Validators.required],      
      last_name: [null, Validators.required],
      email: [null, Validators.required],
      hours: [null, Validators.required], 
      password: ['123'],
    })
  }

  loginPath(){
    this.route.navigate(['/']);  
  }

  onChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedOption = selectElement.value;
    console.log('Selected option:', this.selectedOption);
  }

  onSubmit(){
    let url = 'register/';
    // let payload = {
    //   username: this.RegisterForm.controls.username.value,
    //   firstName: this.RegisterForm.controls.firstName.value,
    //   lastName: this.RegisterForm.controls.lastName.value,
    //   email: this.RegisterForm.controls.email.value,
    //   // password: this.RegisterForm.controls.password.value,
    //   // instanceSize: this.RegisterForm.controls.instanceSize.value,
    //   // dateRent: this.RegisterForm.controls.dateRent.value, 
    //   // timeRent: this.RegisterForm.controls.timeRent.value,
    //   hours: this.RegisterForm.controls.hours.value
    // }
    const payload = this.RegisterForm.value; 
    if(this.selectedOption=="1"){
      payload["is_active"] = true
      payload["is_staff"] = true
      payload["is_superuser"] = false
    }else{
      payload["is_active"] = true
      payload["is_staff"] = false
      payload["is_superuser"] = true
    }

    this.authService.post(this.orlUsersEndPointUrl + url, payload).subscribe((response:any)=>{
      // this.sharedService.orlIsLoggedIn = true;
      // this.authService.userDetails = response?.user;
      this.sharedService.showmsg("user registered");
      this.viewInstance();
      this.invokeUserInstance();
      this.registerPath()
    }, error => this.sharedService.showmsg(error?.error?.message))
  }
  registerPath(){
    this.route.navigate(['/']);
  }
  invokeUserInstance(){
    setTimeout(() => {
      this.viewInstance(); // Execute immediately
      this.sharedService.orlInstanceInterval = setInterval(() => {
        this.viewInstance(); // Execute every second
      }, 5000);
    }, 0); // Zero delay for immediate execution
  }

  viewInstance(){
    let url2 = 'update_active/'
    this.authService.get
    (this.orlUsersEndPointUrl + url2 + this.authService?.userDetails?.id + '/').subscribe((response:any)=>{
    }, error => clearInterval(this.sharedService.orlInstanceInterval))
  }
}
