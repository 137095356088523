<div class="orl-design-area" style="height: 100%;overflow: auto;">
    <div style="display: flex;justify-content: center;padding-top: 1rem;">
        <div class="orl-area--card" style="width: 25rem;">
            <form [formGroup]="orlFeedbackForm" style="display: flex;flex-direction: column;">
                <div style="font-size: 18px;font-weight: bold;text-align: center;padding-top: 1rem;">
                    Feedback Form
                </div>

                <div style="display: flex;flex-direction: column;row-gap: 0.5rem;padding: 1rem;font-weight: bold;">
                    <span style="display: flex;flex-direction: column;row-gap: .3rem;">
                        <span>
                            Subject
                        </span>
                        <span>
                            <input type="text" placeholder=" " style="width: 100%;"
                            formControlName="subject" required/>
                        </span>
                    </span>
                    <span style="display: flex;flex-direction: column;row-gap: .3rem;">
                        <span>
                            Description
                        </span>
                        <span>
                            <textarea style="width: 100%;height: 80px;"
                            formControlName="description" required></textarea>
                        </span>
                    </span>
                    <span style="display: flex;flex-direction: column;row-gap: .3rem;">
                        <span>
                            <div class="text-input-field" style="display: flex;justify-content: center;">
                                <button class="orl-secondary-button"
                                    style="display: flex;align-self: center;align-items: center;column-gap: .5rem;">
                                    <mat-icon>
                                        cloud_upload
                                    </mat-icon>
                                    Upload attachment
                                </button>
                                <span class="choose-file">
                                    <input #imageInput type="file" accept=".jpeg,.jpg,.png"
                                    (change)="changeDoc($event)" (click)="imageInput.value = null!"
                                    style="width:100%;height:100%;cursor:pointer;"/>
                                </span>
                            </div>
                        </span>
                    </span>

                    <!-- File Name + Extension -->
                    <span>
                        {{orlFeedbackForm.controls.attachments.value?.name}}
                    </span>
                </div>

                <div style="display: flex;justify-content: center;padding: 1rem;height: 90%;align-items: center;">
                    <button (click)="orlFeedbackForm.valid ? submitRequestForm() : sharedService.showmsg('Feedback form is invalid')" type="submit" 
                    class="orl-secondary-button"
                    style="display: flex;justify-content: center;font-size: 14px;">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
