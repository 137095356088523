<h2 >Hi Buddy{{data.status}}</h2>
<div  >
  <h3>Your Rental Time end in 10 mins, Do you want extent our service?</h3>

 
</div>
<div>
  <input style="margin-bottom:1rem; height: 2.2rem;width: 40%;padding-left:.5rem;background-color:#FFFFFF; color:white; border-radius: 5px; border: 1px solid gray;" id="selectInputUserName" type="time" #selectInputUserName />
</div>
<div  style="display: flex;flex-direction: rows;column-gap: 2rem; ">

  <button style="height: 2.5rem;width: 30%;padding-left:.5rem;background-color:#26a0da; color:white; border-radius: 5px; border: 1px solid gray;" (click)="onNoClick()">No Thanks</button>
  <button style="height: 2.5rem;width: 30%;padding-left:.5rem;background-color:#26a0da; color:white; border-radius: 5px; border: 1px solid gray" (click)="okClick()" >Ok</button>
</div>

<!-- 

<h1 mat-dialog-title>Hi {{data.name}}</h1>
<div mat-dialog-content>
  <p>What's your favorite animal?</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.animal">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.animal" cdkFocusInitial>Ok</button>
</div> -->