import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  p40sHeader: string = '';
  constructor(public sharedService: SharedService, public route: Router,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.sharedService.orlHeader$.subscribe((arg) => {
      this.p40sHeader = arg;
    });
  }

  logout(){
    this.sharedService.orlIsLoggedIn = false;
    this.authService.userDetails = {};
    this.route.navigate(['/']);
    window.location.reload() 
    clearInterval(this.sharedService.orlInstanceInterval);
  }

}