import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackPageComponent } from './components/feedback-page/feedback-page.component';
import { FeedbackListComponent } from './components/feedback-list/feedback-list.component';
import { AuthServiceGuard } from '../auth/services/auth-service.guard';
import { RegisterComponent } from '../auth/components/register/register.component';

const routes: Routes = [
  { path: 'feedback', component: FeedbackPageComponent, canActivate:[AuthServiceGuard]},
  { path: 'feedback-list', component: FeedbackListComponent, canActivate:[AuthServiceGuard]},
  {path:'register', component: RegisterComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FeedbackRoutingModule { }
