<div style="height: calc(100vh - 80px);overflow: auto;">
    <div *ngIf="orlLabLoader"
        style="display: flex;justify-content: center;align-items: center;width: 100%;height: calc(100vh - 70px);">
        <div class="loader"></div>
    </div>

    <div *ngIf="!orlLabLoader">
        <div *ngIf="!orlHasInstance" style="display: flex;justify-content: center;flex-direction: column;
        padding: 2rem 1rem 2.5rem 0.7rem; row-gap:1.5rem;">
            <div style="display: flex; font-size: 30px; font-weight: bold;">
                Request Lab
            </div>
    
            <div [formGroup]="form" style="display: flex;column-gap: 1.5rem;">
                <div style="align-self: center;">
                    <select style="
                    height: 2.6rem;
                    width: 13rem; margin-right: -0.7rem; font-weight: bold;
                "
                formControlName="scriptType">
                        <option value="0">
                            AWS Instance - Tera Form
                        </option>
                        <!-- <option value="2">
                            TerraForm Script
                        </option> -->
                        <!-- <option value="3">
                            CloudForm Script
                        </option> -->
                    </select>
                </div>
                <div style="display: flex;column-gap: 1.5rem;">
                    <div style="align-self: center;">
                        <input type="text" value="T3 Micro" formControlName="instance_size" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                        placeholder="instance_size" required>
                    </div>
                </div>
            <!-- <div style="display: flex;column-gap: 1.5rem;">
                <div style="align-self: center;">
                    <input type="number" formControlName="hours" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                    placeholder="hours" required>
                </div> 
            </div> -->
            <div  style="display: flex;column-gap: 1.5rem;">
                <div style="align-self: center;">
                    <input type="time" formControlName="rentHours" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                    placeholder="Rent Hours" required>
                </div>
                </div>
                <div style="display: flex;column-gap: 1.5rem;justify-content: center;flex-direction: column; ">
                    <button (click)="[launchInstance(), launchButton.disabled=true]" class="orl-secondary-button"
                        #launchButton>
                        Launch
                    </button>
                </div>
            </div>
    
        </div>
    
        <div *ngIf="orlHasInstance" style="display: flex;justify-content: center;flex-direction: column;
            padding: 5rem 0 0 3.5rem;row-gap:1.5rem;">
            <div *ngIf="orlLabDetails[0]?.instance_type == 1" style="display: flex;column-gap: .5rem;">
                <span><img src="./assets/images/aws instance.png"
                    style="
                    width: 50px;
                    height: 50px;
                "></span>
                <span
                style="
                font-size: 25px;
                align-self: center;
            ">AWS Instance</span>
            </div>
    
    
            <div *ngIf="orlButtonStatus != ''" 
                style="display: flex;column-gap: .5rem;align-items: center;font-weight: bold;font-size: 19px;">
                <div style="width: 12rem;color: gray;">Instance state : </div>
                <div>
                    <span class="loading" [ngStyle]="{'color': orlButtonStatus == 'Launching' ? 'green' : 'red'}">{{orlButtonStatus}}</span>
                </div>
            </div>
            <div *ngIf="orlButtonStatus != ''"  
                style="display: flex;column-gap: .5rem;align-items: center;font-weight: bold;font-size: 19px;">
                <div style="width: 12rem;color: gray;">Username : </div>
                <div>
                    CentOS
                </div>
            </div>
            <div style="display: flex;column-gap: .5rem;align-items: center;font-weight: bold;">
                <div style="font-size: 19px;width: 12rem;color: gray;">IP address : </div>
                <div style="width:15rem;height: 1rem; border: 1px solid black;border-radius:8px;padding:1rem;">
                    <span>{{orlLabDetails1}}</span>
                </div>
                <button *ngIf="orlButtonStatus == ''" (click)="[destroyInstance(orlLabDetails[0]?.user_instance_id), destroyButton.disabled=true]" class="orl-secondary-button"
                    style="display: flex;justify-content: center;font-size: 14px; background-color:rgb(173, 54, 54)"
                    #destroyButton>
                    Destroy
                </button>
            </div>
            <div style="display: flex;column-gap: 1rem;align-items: center;font-weight: bold;">
                <div style="font-size: 19px;width: 12rem;color: gray;">PEM file : </div>
                <button mat-icon-button mat-button (click)="downloadPem()"
                    style="cursor: pointer;height: 2.5rem;width: 2.5rem;">
                    <mat-icon style="font-size: 25px;color: white; background-color: #26a0da;">cloud_download</mat-icon>
                </button>
            </div>

            <div style="display: flex;column-gap: 1rem;align-items: center;font-weight: bold; margin-bottom:2rem">
                <div style="font-size: 19px;width: 12rem;color: gray;">Putty Document : </div>
                <button mat-icon-button mat-button (click)="downloadPutty()"
                    style="cursor: pointer;height: 2.5rem;width: 2.5rem;">
                    <mat-icon style="font-size: 25px;color: white; background-color: #26a0da;">get_app</mat-icon>
                </button>
            </div>

        </div>
    </div>

    <div style="width: 90%; padding-left:1rem">
        <div class="example-container mat-elevation-z8">
            <mat-table #table [dataSource]="dataSource">
          
              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->
          
              <!-- Position Column -->
              <ng-container matColumnDef="Instance Id">
                <mat-header-cell *matHeaderCellDef> Instance Id </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.user_instance_id}} </mat-cell>
              </ng-container>
          
              <!-- Name Column -->
              <ng-container matColumnDef="User Name">
                <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.userName}} </mat-cell>
              </ng-container>
          
              <!-- Weight Column -->
              <ng-container matColumnDef="IP Address">
                <mat-header-cell *matHeaderCellDef> IP Address </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.instance_ip}} </mat-cell>
              </ng-container>

              <ng-container matColumnDef="Instance Type">
                <mat-header-cell *matHeaderCellDef> Instance Type </mat-header-cell>
                <mat-cell *matCellDef="let element"> Terra Form </mat-cell>
            </ng-container>

              
              <!-- Symbol Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
              </ng-container>
          
              <ng-container matColumnDef="Timestamp">
                <mat-header-cell *matHeaderCellDef> Created At </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.timestamp | date: 'dd/MM/yyyy hh:mm a'}} </mat-cell>
              </ng-container>
          
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
          </div>

          <div style="display: flex;column-gap: 1rem;align-items: center;font-weight: bold; margin-top:2rem">
            <div style="font-size: 19px;width: 12rem;color: gray;">Export Data</div>
            <button mat-icon-button mat-button (click)="downloadData()"
                style="cursor: pointer;height: 2.5rem;width: 2.5rem;">
                <mat-icon style="font-size: 25px;background-color: #26a0da; color:white">get_app</mat-icon>
            </button>
        </div>
          
        
    </div>
</div>