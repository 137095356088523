import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SupportPageComponent } from './components/support-page/support-page.component';
import { SupportListComponent } from './components/support-list/support-list.component';
import { AuthServiceGuard } from '../auth/services/auth-service.guard';

const routes: Routes = [
  { path: 'support', component: SupportPageComponent, canActivate:[AuthServiceGuard]},
  { path: 'support-list', component: SupportListComponent, canActivate:[AuthServiceGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SupportRoutingModule { }
