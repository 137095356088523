<div style="background: linear-gradient(45deg, #26a0da, #314755, #314755 150%);
    height: calc(157vh - 19.5rem);overflow: auto;">
    <div style="display: flex;justify-content: center;align-self: center;height: 100%;">
        <div class="orl-area--card" style="align-self: center;width: 22rem;
        box-shadow: none;">
            <div style="display: flex;flex-direction: column;height: 100%;padding: 1rem;row-gap: 1.5rem;">
                <span style="font-size: 25px;height: 10%;text-align: center;">
                    Register In With
                </span>

                <div style="display: flex;flex-direction: column;row-gap: 1.5rem;">


                    <form [formGroup]="RegisterForm" (ngSubmit)="onSubmit()"
                    style="display: flex;flex-direction: column;row-gap: 1rem;align-items: center;
                    justify-content: space-evenly;">

                        <div style="display: flex;flex-direction: column;row-gap: 1.5rem;width: 100%;">
                            <input type="text" formControlName="username" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                            placeholder="Username" required>
          
                            <div style="display: flex;flex-direction: column;row-gap: 1.5rem;width: 100%;">
                                <input type="text" formControlName="first_name" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                                placeholder="First Name" required>

                        <div style="display: flex;flex-direction: column;row-gap: 1.5rem;width: 100%;">
                            <input type="text" formControlName="last_name" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                            placeholder="Last Name" required>

                        <div style="display: flex;flex-direction: column;row-gap: 1.5rem;width: 100%;">
                            <input type="text" formControlName="email" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                            placeholder="Email Id" required>
                        

                            <div style="display: flex;flex-direction: column;row-gap: 1.5rem;width: 103%;">
                       
                            <select style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;" id="selectInput" (change)="onChange($event)">
                                <option *ngFor="let option of options" [value]="option.value">
                                {{ option.viewValue }}
                                </option>
                            </select> 
 
                            <span style="width: 100%;display: flex; justify-content: center;">
                                <button class="orl-secondary-button"  type="submit"
                                class="orl-primary-button login-button">
                                    Register
                                </button>
                            </span>
    
                            <div style="display: flex;justify-content: center;align-items: center;color: gray;column-gap: .3rem;">
                                <a>Already User?</a> <a (click)="loginPath()" style="color: black; cursor: pointer">Sign in now</a>
                            </div>
                       
 
   </div>
                        </div>
<!-- 
                <div style="display: flex;justify-content: center;">
                    <span class="google-circle--button">
                        <img src="./assets/images/gimage.png" width="25" style="height: 100%;">
                    </span>
                </div> -->

                
                <!-- <div style="display: flex;justify-content: center;align-items: center;color: gray;column-gap: .3rem;">
                    <span>Not a member?</span> <span (click)="registerPath()" style="color: black;">Sign up now</span>
                </div> -->
            </div>


        </div>
    </div>

 