import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public orlIsSideNav: boolean = false;
  public orlIsLoggedIn: boolean = false;
  public orlHeader$ = new BehaviorSubject<any>(null);
  public orlInstanceInterval: any;
  constructor(public snackbar: MatSnackBar) { }

  /** APPLICATION PROGRAMMING INTERFACE SERVICE ENTRY URL */
  public onRequestLabService:any = {
    feedback : environment.apiURL  + 'api/feedback/',
    support : environment.apiURL  + 'api/support/',
    users: environment.apiURL  + 'api/v1/users/', 
    lab: environment.apiURL  + 'api/v1/lab/', 
  };
 
  setHeader(arg: any) {
    this.orlHeader$.next(arg);
  }

  showmsg(arg: any) {
    this.snackbar.open(arg, '✖', {
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center'
    });
  }
}


