import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { LandingPageComponent } from './auth/components/landing-page/landing-page.component';
import { UserDetailsComponent } from './auth/components/user-details/user-details.component';
import { AuthServiceGuard } from './auth/services/auth-service.guard';
import { FeedbackPageComponent } from './feedback/components/feedback-page/feedback-page.component';
import { RegisterComponent } from './auth/components/register/register.component';
import { SupportPageComponent } from './supports/components/support-page/support-page.component';

const routes: Routes = [
  {path:'', component: LoginComponent},
  {path:'register', component: RegisterComponent},
  {path:'homepage', component: LandingPageComponent, canActivate:[AuthServiceGuard]},
  {path: 'feedback', component: FeedbackPageComponent, canActivate:[AuthServiceGuard]},
  {path: 'support', component: SupportPageComponent, canActivate:[AuthServiceGuard]},
  {path: 'user-details', component: UserDetailsComponent, canActivate:[AuthServiceGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
