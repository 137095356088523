import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { AuthService } from '../../services/auth.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-instancelist-page',
  templateUrl: './instancelist-page.component.html',
  styleUrls: ['./instancelist-page.component.scss']
})
export class InstancelistPageComponent implements OnInit {
  

  displayedColumns = ['Instance Id', 'User Name', 'IP Address', 'Instance Type', 'status', 'Timestamp'];

  displayedUserColumns = ['User Id', 'User Name', 'First Name', 'Last Name', 'Email', 'Is Staff', 'Joined Date', 'Last Active'];

  public dataSource: Element[] = [];

  public dataSourceUser: Element[] = [];

  options = [
    { value: 'All', viewValue: 'All' }, 
    { value: 'Launching', viewValue: 'Launching' }, 
    { value: 'Launched', viewValue: 'Launched' },
    { value: 'Destorying', viewValue: 'Destorying' }, 
    { value: 'Destroyed', viewValue: 'Destroyed' }, 
  ];
  selectedOption: string | undefined  

  public orlFeedbackEndPointUrl: string = '';
  public orlFirstCard: any = [];
  public orlLabsEndPointUrl: string = '';
  public orlLabDetails: any = [];
  public orlResourceIpList: any = [];
  public orlHomepageLoader: boolean = false;
  constructor(public sharedService: SharedService, public authService: AuthService) {
    this.orlFeedbackEndPointUrl = this.sharedService.onRequestLabService?.feedback;
    this.orlLabsEndPointUrl = this.sharedService.onRequestLabService?.lab;
  }
  getInstaneList(){
    let url = 'alluserinst/';
    // alert(this.authService.userDetails?.id)
    this.authService.get(this.orlLabsEndPointUrl + url)
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.dataSource = this.orlLabDetails;
      console.log(response, "lists")
    })
  }
  
  getFliterInstane(){
    let url = 'alluserinst/';
    this.userName = this.userNameInput.nativeElement.value;
    console.log(this.userName); // Log the new user name or handle it as needed
    // alert(this.authService.userDetails?.id)
    this.authService.post(this.orlLabsEndPointUrl + url, {userName: this.userName, type: "userName"})
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.dataSource = this.orlLabDetails;
      console.log(response, "lists")
    })
  }
  
  downloadUserData():void{
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSourceUser);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    XLSX.writeFile(wb, 'AllUserDataExport.xlsx');
  }

    
  downloadData():void{
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataSource);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    
    XLSX.writeFile(wb, 'AllInstanceDataExport.xlsx');
  }
  
  getUserList(){
    let url = 'http://3.7.21.214:8000/api/v1/users/userlist/';
    // alert(this.authService.userDetails?.id)
    this.authService.get(url)
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.dataSourceUser = this.orlLabDetails;
      console.log(response, "lists")
    })
  }
  @ViewChild('selectInputUserName') userNameInput!: ElementRef;

  userName: string = '';

 
  onChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedOption = selectElement.value;
    console.log('Selected option:', this.selectedOption);
    let url = 'alluserinst/';
    this.userName = this.userNameInput.nativeElement.value;
    console.log(this.userName); // Log the new user name or handle it as needed
    // alert(this.authService.userDetails?.id)
    this.authService.post(this.orlLabsEndPointUrl + url, {status: this.selectedOption, type: "status"})
    .subscribe((response:any)=>{
      this.orlLabDetails = response;
      this.dataSource = this.orlLabDetails;
      console.log(response, "lists")
    })
  }

  ngOnInit(): void {
    this.selectedOption = "All"
    this.getInstaneList();
    this.getUserList();
    this.orlHomepageLoader = true;
    this.sharedService.setHeader('Homepage');
    if(!this.authService.userDetails?.is_superuser){
      this.orlFirstCard.push(
        {
          value: 'Python Certification'
        },
        {
          value: 'Certified Kubernetes Application Developer'
        },
        {
          value: 'Certified Kubernetes Security Specialist'
        },
        {
          value: 'AWS Cloud Practitioner'
        },
        {
          value: 'Microsoft Azure Solutions Architect Expert'
        }
      )
      this.orlHomepageLoader = false;
    }

    else{
      this.orlHomepageLoader = true;
      this.orlFirstCard = [];
      this.authService.get(this.orlFeedbackEndPointUrl + 'feedback_vc/').subscribe((response:any)=>{
        if(response.length > 0){
          response.forEach((feedbackList:any, index: number) => {
            if(index <= 4){
              this.orlFirstCard.push({value:feedbackList?.subject});
            }
            // for(var i=0; i<=index; i++)
          });
        }

      }, error => this.orlHomepageLoader = false)


      
      let user1Id = 2;
      let user2Id = 3;
      let url1 = 'userinst/' + user1Id;
      let url2 = 'userinst/' + user2Id;
      this.orlResourceIpList = [];
      forkJoin([this.authService.get(this.orlLabsEndPointUrl + url1), this.authService.get(this.orlLabsEndPointUrl + url2)])
      .subscribe((response:any)=>{

        /** User1 */
        if(response[0]?.length > 0){
          this.orlResourceIpList.push({ip: response[0][0]?.instance_ip, username: 'user1'});
        }
        /** User2 */
        if(response[1]?.length > 0){
          this.orlResourceIpList.push({ip: response[1][0]?.instance_ip, username: 'user2'});
        }
        // this.orlLabDetails = response;
        this.orlHomepageLoader = false;
      }, error => this.orlHomepageLoader = false)

        // this.orlLabLoader = true;
        // let url = 'userinst/' + this.authService.userDetails?.id;
        // this.authService.get(this.orlLabsEndPointUrl + url)
        // .subscribe((response:any)=>{
        //   this.orlLabDetails = response;
        //   if(this.orlLabDetails.length > 0){
        //     this.orlHasInstance = true;
    
        //     if(this.orlLabDetails[0].processing_status === true){
        //       this.orlButtonStatus = 'Launching';
        //       if(this.orlInstanceInterval == undefined){
        //         this.invokeViewInstance();
        //       }
        //     }
        //     else if(this.orlLabDetails[0].processing_status === false){
        //       this.orlButtonStatus = 'Destroying';
        //       if(this.orlInstanceInterval == undefined){
        //         this.invokeViewInstance();
        //       }
        //     }
        //     else if(this.orlLabDetails[0].processing_status === null){
        //       this.orlButtonStatus = '';
        //       this.clrInstInterval();
        //     }
        //     this.orlLabLoader = false;
        //   }
        //   else{
        //     this.orlHasInstance = false;
        //     // this.orlLabLoader = true;
        //     // this.clrInstInterval();
        //     this.orlLabLoader = false;
        //   }
    
        // })
    }
  }

}
