<div style="background: linear-gradient(45deg, #26a0da, #314755, #314755 150%);
    height: calc(157vh - 19.5rem);overflow: auto;">
    <div style="display: flex;justify-content: center;align-self: center;height: 100%;">
        <div class="orl-area--card" style="align-self: center;width: 22rem;
        box-shadow: none;">
            <div style="display: flex;flex-direction: column;height: 100%;padding: 1rem;row-gap: 1.5rem;">
                <span style="font-size: 25px;height: 10%;text-align: center;">
                    Sign In With
                </span>

                <div style="display: flex;flex-direction: column;row-gap: 1.5rem;">
                    <!-- <span style="display: flex;justify-content: center;">
                        <button (click)="sharedService.orlIsLoggedIn = true;route.navigate(['/landing-page'])" class="orl-secondary-button" 
                        style="padding: 0.7rem;display: flex;align-items: center;font-size: 14px;font-weight: bold;
                          box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 8px 0 rgba(0,0,0,0.19);border-radius: 5px;border: 1px solid #ffff;">
                            <img src="./assets/images/google.png" height="23" width="40">
                            <span style="align-self: flex-end;">Google</span>
                        </button>
                    </span> -->

                    <form [formGroup]="loginForm"  
                    style="display: flex;flex-direction: column;row-gap: 1rem;align-items: center;
                    justify-content: space-evenly;">
                        <div style="display: flex;flex-direction: column;row-gap: 2rem;width: 100%;">
                            <input type="text" formControlName="username" style="height: 2.5rem;width: 100%;padding-left:.5rem;background-color:#f5f5f5;"
                            placeholder="Username" required>
    
                            <span style="width: 100%;display: flex; justify-content: center;">
                                <button class="orl-secondary-button" (click)="submitLogin()"
                                class="orl-primary-button login-button">
                                    Login
                                </button>
                            </span>
    
                        </div>
                    </form>
                </div>

                <div style="display: flex;justify-content: center;">
                    <span class="google-circle--button">
                        <img src="./assets/images/gimage.png" width="25" style="height: 100%;">
                    </span>
                </div>

                
                <div style="display: flex;justify-content: center;align-items: center;color: gray;column-gap: .3rem;">
                    <a>Not a member?</a> <a (click)="registerPath()" style="color: black; cursor: pointer">Sign up now</a>
                </div>
            </div>


        </div>
    </div>

</div>