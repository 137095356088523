import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /** Store User Details */
  public userDetails: any;
  constructor(private p40httpClient: HttpClient, public SharedService: SharedService) {}

  post(url: string, payload: any){
    return this.p40httpClient.post(url, 
    payload)
  }

  get(url:string){
    return this.p40httpClient.get(url)
  }

  put(url:string, payload: any){
    return this.p40httpClient.put(url, payload)
  }

}
