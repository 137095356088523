
<div *ngIf="p40TableLoader && orlFeedbackList.length === 0"
    style="display: flex;justify-content: center;align-items: center;width: 100%;height: calc(100vh - 70px);">
    <div class="loader"></div>
</div>
<!-- <div  *ngIf="p40TableLoader && orlFeedbackList.length === 0" class="loader"></div> -->

<div *ngIf="!p40TableLoader && orlFeedbackList.length > 0" style="padding-top: 3rem;">
    <table mat-table [dataSource]="orlFeedbackList" class="example-table"
    matSort matSortActive="created" matSortDisableClear matSortDirection="desc">
    <ng-container matColumnDef="userId" style="width: 50px;">
    <th mat-header-cell *matHeaderCellDef>User Id</th>
    <td mat-cell *matCellDef="let row">{{row.user}}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="userName" style="width: 50px;">
        <th mat-header-cell *matHeaderCellDef>User Name</th>
        <td mat-cell *matCellDef="let row">{{row.userName}}</td>
    </ng-container> -->
    <ng-container matColumnDef="subject" style="width: 90px;">
    <th mat-header-cell *matHeaderCellDef>Subject</th>
    <td mat-cell *matCellDef="let row">{{row.subject}}</td>
    </ng-container>
    
    <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let row" style="word-wrap: normal;width: 350px;">
        {{(row.description.length > 150) ? (row.description | slice:0:150) + '...' : row.description}}
    </td>
    </ng-container>

    <ng-container matColumnDef="timestamp" style="width: 60px;">
    <th mat-header-cell *matHeaderCellDef>Created date</th>
    <td mat-cell *matCellDef="let row">{{row.timestamp}}</td>
    </ng-container>

    <ng-container matColumnDef="upload" style="width: 20px;">
        <th mat-header-cell *matHeaderCellDef>Attachment</th>
        <td mat-cell *matCellDef="let row">{{row.upload == null ? 'No' : 'Yes' }}</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="orlTableHeader"></tr>
    <tr mat-row *matRowDef="let row; columns: orlTableHeader;" style="cursor: pointer;" 
        (click)="openFeedbackDetail(row)"></tr>
    </table>
</div>

