import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { SupportPageComponent } from './components/support-page/support-page.component';
import { SupportListComponent } from './components/support-list/support-list.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { SupportDetailsComponent } from './components/support-details/support-details.component';


@NgModule({
  declarations: [SupportPageComponent, SupportListComponent, SupportDetailsComponent],
  imports: [
    CommonModule,
    SupportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule
  ]
})
export class SupportModule { }
